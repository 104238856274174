import React, { useEffect, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { Button, Col, Collapse, Modal, Row, Upload, UploadProps, message } from 'antd';
import { AxiosError } from 'axios';
import instance from 'config/_axios';
import { editor_buttonList } from 'config/constants/suneditor';
import { IExamQuestion, IExamStudent } from 'models/exam';
import FileViewer from 'components/FileViewer/file_viewer';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import SunEditor from 'suneditor-react';
import { AttachFilled, ExpandUpRightRegular } from '@fluentui/react-icons';
import "./style.scss";
import dayjs from 'dayjs';
import { toHHmmss } from 'utils/others_functions';
import { useAppSelector } from 'store';

type TypeQuestionExamType = {
  data: IExamStudent | undefined;
  type: string | undefined;
};

const panelStyle: React.CSSProperties = {
  marginBottom: 24,
  background: "#fff",
  borderRadius: ".5rem",
  border: 'none',
};

const props: UploadProps = {
  name: 'file',
  maxCount: 1,
  customRequest: ({ onSuccess }: any) => { onSuccess("ok") },
  // headers: {
  //   authorization: 'authorization-text',
  // },

  onChange(info) {
    if (info.file.status === 'done') {
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === 'error') {
      info.file.status = 'done';
      message.error(`${info.file.name} file upload failed.`);
    }
  },
};

const QuestionExamType: React.FC<TypeQuestionExamType> = ({ data, type }): JSX.Element => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { t } = useTranslation();
  const user = useAppSelector(p => p.auth.user);
  const user_id = user?.user_id;
  const [text, settext] = useState<{ [key: number]: string }>(JSON.parse(localStorage.getItem(`exam_student_text_${user_id}_${id}`) ?? "{}"));
  const [file, setFile] = useState<{ [key: number]: any }>({});
  const [time, setTime] = useState<number>(0);
  const [open, setOpen] = useState<{ file: string | null | any, locale: boolean }>({ file: null, locale: false });
  const [q, setQ] = useState<boolean>(false);
  const [questions, setQuestions] = useState<IExamQuestion[]>([]);

  useEffect(() => {
    setQuestions(data?.examStudentQuestion ?? []);
    const finish = (data?.exam?.finish_time ?? 0) - (data?.studentTimes?.current ?? 0);
    const _time = (data?.exam?.duration ?? 0) * 60 - ((data?.studentTimes?.current ?? 0) - (data?.studentTimes?.start ?? 0));
    if ((data?.studentTimes?.finish ?? 0) < (data?.studentTimes?.current ?? 1)) {
      setTime(0);
    } else {
      if (_time < finish)
        setTime(_time);
      else
        setTime(finish);
    }

    if (data && ((data?.studentTimes?.finish ?? 0) <= (data?.studentTimes?.current ?? 0) || (data?.exam?.finish_time ?? 0) <= (data?.studentTimes?.current ?? 0))) {

      Modal.error({
        title: "Imtihon vaqti yakunlangan!!!",
        content: "Ortga qaytish uchun 'ok' tugmasini bosing",
        onOk: () => {
          if (data?.status < 2) finishTest();
          else navigate(`/exams?tab=finaly-exam&semestr_id=${data?.edu_semestr_id}`);
        },
        okButtonProps: { disabled: finishLoading }
      });
    } else if (data && !data?.examStudentQuestion?.length) {
      Modal.error({
        title: "Savollar mavjud emas!!!",
        content: "Ortga qaytish uchun 'ok' tugmasini bosing",
        onOk: () => { navigate(`/exams?semestr_id=${data?.edu_semestr_id}&tab=finaly-exam`) },
        okButtonProps: { disabled: finishLoading }
      });
    } else {
      setQ(p => !p);
    }
  }, [data]);

  useEffect(() => {
    if (data && time > 0) {
      const interval = setInterval(() => {
        setTime(p => {
          if (p <= 0) {
            clearInterval(interval);
            Modal.warning({
              title: 'Imtihon vaqti yakunlandi',
              content: "Ortga qaytish uchun 'ok' tugmasini bosing",
              onOk: () => { finishTest() },
              okButtonProps: { disabled: finishLoading }
            });
            return 0;
          }
          return p - 1
        });
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [q]);

  const writeText = (_id: number, answer: string) => {
    const _text = { ...(text ?? {}), [_id]: answer };
    settext(_text);
    localStorage.setItem(`exam_student_text_${user_id}_${id}`, JSON.stringify(_text))
  }

  const { mutate: save, isLoading: saveLoading } = useMutation({
    mutationFn: async (id: number) => {
      const formdata = new FormData();

      // formdata.append("id", id?.toString());
      if (text[id]) formdata.append("answer_text", text[id]);
      if (file[id]) formdata.append("upload_file", file[id]?.originFileObj);

      const response = await instance({
        url: `/exam-student-questions/${id}`,
        method: "PUT",
        data: formdata,
      });
      return response.data;
    },
    onSuccess: async (res) => {
      if (res?.status === 1) {
        // setTests(res.data);
        message.success("Bitta savol saqlandi")
      } else {
        message.error(res?.data ? res?.data?.message : "")
      }
    },
    onError: (error: AxiosError<any>) => {
      message.error(error?.response?.data ? error?.response?.data?.message : "")
    },
    retry: 0,
  });

  const { mutate: finishTest, isLoading: finishLoading } = useMutation({
    mutationFn: async () => {

      const response = await instance({
        url: `/exam-students/finish/${id}`,
        method: "PUT",
      });
      return response.data;
    },
    onSuccess: async (res) => {
      if (res?.status === 1) {
        localStorage.removeItem(`exam_student_text_${user_id}_${id}`);
        navigate(`/exams?semestr_id=${data?.edu_semestr_id}&tab=finaly-exam`);
      } else {
        message.error(res?.data ? res?.data?.message : "")
      }
    },
    onError: (error: AxiosError<any>) => {
      message.error(error?.response?.data ? error?.response?.data?.message : "")
    },
    retry: 0,
  });

  return (
    <div className="question-wrapper bg-[#f7f7f7]">
      <Row >
        <Col span={18} xs={24} md={24} lg={24} xl={18} className='px-4 max-lg:px-2' >
          <Collapse
            bordered={false}
            defaultActiveKey={['0']}
            className='bg mt-4'
            expandIconPosition="end"
          >
            {
              questions?.map((e, i) => (
                <Collapse.Panel key={i}
                  header={<div>
                    <div dangerouslySetInnerHTML={{ __html: e?.question?.text ?? "" }} />
                  </div>}
                  style={panelStyle}
                >
                  {e?.question?.file ? <div className='d-f mt-2 mb-4' >
                    <span className="opacity-60">Tposhiriq fayli:</span>
                    <Button onClick={() => setOpen({ file: e?.question?.file, locale: false })} type='primary' size='small' className='inline-flex items-center ms-4'>
                      <ExpandUpRightRegular fontSize={16} />&nbsp;&nbsp;File
                    </Button>
                  </div> : null}
                  <div className='mt-2'>
                    <SunEditor
                      setContents={(text[e?.id] ?? e?.question?.answer_text ?? "")}
                      height='240px'
                      autoFocus={true}
                      placeholder={t("Enter content text") ?? ""}
                      onChange={(text) => { writeText(e?.id, text) }}
                      setOptions={{
                        fontSize: [12, 14, 16, 18, 20, 24, 32],
                        fontSizeUnit: "px",
                        buttonList: editor_buttonList
                      }} />
                    <div className="flex-between mt-4">
                      <div>
                        <Upload
                          {...props}
                          accept='.pdf'
                          fileList={file[e?.id] ? [file[e?.id]] : undefined}
                          onChange={(file) => { setFile(p => file.file?.status !== "removed" ? ({ ...(p ?? {}), [e?.id]: file?.file }) : p) }}
                          onRemove={() => { setFile(p => { let obj = { ...p }; delete (obj[e?.id]); return obj }); return true }}
                          onPreview={(file) => { if (file.originFileObj) setOpen({ file: window.URL.createObjectURL(file.originFileObj), locale: true }) }}
                        >
                          <Button icon={<AttachFilled className='text-[18px] mr-2' />} className='items-center flex'>Click to Upload</Button>
                        </Upload>
                        {!file[e?.id] && e?.question?.answer_file ?
                          <Button type="text" size='small' className='mt-2 bg' onClick={() => setOpen({ file: e?.question?.answer_file, locale: false })} >Javob fayli</Button>
                          : null}
                      </div>
                      <Button type='primary' className='px-4' loading={saveLoading} onClick={() => save(e?.id)} >Saqlash</Button>
                    </div>
                  </div>
                </Collapse.Panel>
              ))
            }
          </Collapse>
        </Col>
        <Col span={6} xs={24} md={24} lg={24} xl={6} className='lg:pt-4 lg:pe-4' >
          <div className=" min-h-[320px] sticky max-lg:relative top-4 rounded-lg shadow-sm p-4 bg-white ">
            <h4 className='text-center' >{data?.exam?.name}</h4>
            <h2 className='text-center text-3xl my-5' >{toHHmmss(time)}</h2>
            <div className="flex flex-col gap-2">
              <p className='font-medium'>
                <span className='text-black text-opacity-50 me-2'>Davomiyligi:</span>
                <span>{data?.exam?.duration ?? 0} minut</span>
              </p>
              <p className='font-medium'>
                <span className='text-black text-opacity-50 me-2'>Boshlanish vaqti:</span>
                <span>{dayjs((data?.exam?.start_time ?? 0) * 1000).format("YYYY.MM.DD HH:mm")}</span>
              </p>
              <p className='font-medium'>
                <span className='text-black text-opacity-50 me-2'>Tugash vaqti:</span>
                <span>{dayjs((data?.exam?.finish_time ?? 0) * 1000).format("YYYY.MM.DD HH:mm")}</span>
              </p>
            </div>
            <div className="absolute bottom-0 left-0 right-0 rounded-md">
              <div className="w-full flex justify-center py-2 px-4 pb-4">
                <Button type='primary' danger loading={finishLoading} onClick={() => {
                  Modal.confirm({
                    title: 'Imtihonni yakunlash',
                    content: <div>
                      <span> Imtihonni yakunlamoqchimisiz? </span>
                    </div>,
                    okText: "Yakunlash",
                    cancelText: "Bekor qilish",
                    onOk: () => { finishTest() },
                    okButtonProps: { disabled: finishLoading }
                  });
                }} >Imtihonni yakunlash</Button>
              </div>
            </div>
          </div>
        </Col>
      </Row>

      <Modal open={!!open.file} width={"90%"} style={{ top: 20 }} title="File viewer" onCancel={() => setOpen({ file: null, locale: false })} onOk={() => setOpen({ file: null, locale: false })} >
        {open.locale ? <iframe className='w-full min-h-[460px]' src={open.file ?? ""} />
          : <FileViewer file={open.file ?? ""} />}
      </Modal>
    </div>
  );
};

export default QuestionExamType;