import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import { Modal, Spin } from "antd";
import HeaderExtraLayout from "components/HeaderPage/headerExtraLayout";
import useGetOneData from 'hooks/useGetOneData';
import { IExamStudent } from 'models/exam';
import { useNavigate, useParams } from 'react-router-dom';
import useUrlQueryParams from 'hooks/useUrlQueryParams';
import QuestionExamType from '../ExamTypes/question';
import TestExamType from '../ExamTypes/test';
import TestView from '../ExamTypes/test_view';
import QuestionExamTypeView from '../ExamTypes/question_view';

const ExamStudent: React.FC = (): JSX.Element => {
  const { t } = useTranslation();
  const { id, type } = useParams();
  const navigate = useNavigate();
  const { urlValue } = useUrlQueryParams({});
  const [_data, setData] = useState<IExamStudent>()

  const { data, isFetching, refetch } = useGetOneData<IExamStudent>({
    queryKey: ["exam-students", id],
    url: `/exam-students/${id}?expand=fileInformation,exam,studentTimes,examStudentQuestion.question.options,${type === "view" ? "examStudentQuestion.isCorrect" : ""}`,
    options: {
      onSuccess: (res) => {
        setData(res.data);
      },
      onError: () => {
        Modal.error({
          title: "Malumot olishda xatolik!!!",
          content: "Ortga qaytish uchun 'ok' tugmasini bosing",
          onOk: () => {
            navigate(`/exams?tab=finaly-exam`);
          }
        });
      },
      enabled: !!id,
    }
  });

  return (
    <div className="">
      <HeaderExtraLayout
        title={data?.data?.exam?.name ?? "Exam"}
        isBack
        backUrl={`/exams?tab=finaly-exam`}
        breadCrumbData={[
          { name: "Home", path: '/' },
          { name: "Exams", path: `/exams?tab=finaly-exam` },
          { name: "Exam student", path: "exam-student" }
        ]}
      />
      <Spin spinning={isFetching}>
        {
          urlValue?.filter_like?.type === "1" ? (type === "view") ? <QuestionExamTypeView data={data?.data} /> : <QuestionExamType data={data?.data} type={type} />
            : urlValue?.filter_like?.type === "2" ? (type !== "view") ? <TestExamType data={_data} refetch={refetch} /> : <TestView data={data?.data} />
              : null
        }
      </Spin>
    </div>
  );
};

export default ExamStudent;

/**
  * exam-student_index
  * exam-student_delete
  * exam-student_update
  * exam-student_view
*/