import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Spin } from "antd";
import HeaderExtraLayout from "components/HeaderPage/headerExtraLayout";
import { CreateBtn } from "components/Buttons";
import useGetOneData from "hooks/useGetOneData";
import { IExamControlStudent } from "models/exam";
import { useParams } from "react-router-dom";
import TestExamType from "./ExamTypes/test";
import useUrlQueryParams from "hooks/useUrlQueryParams";
import TestView from "./ExamTypes/test_view";
import QuestionExamType from "./ExamTypes/question";
import FileExamType from "./ExamTypes/file";

const ExamControlStudent: React.FC = (): JSX.Element => {
  const { t } = useTranslation();
  const { id, type } = useParams();
  const { urlValue } = useUrlQueryParams({});
  const [_data, setData] = useState<IExamControlStudent>();

  const { data, isFetching, refetch } = useGetOneData<IExamControlStudent>({
    queryKey: ["exam-control-students", id],
    url: `/exam-control-students/${id}?expand=fileInformation,examControl,studentTimes,examControlTest.test.options,${
      type === "view" ? "examControlTest.isCorrect" : ""
    }`,
    options: {
      onSuccess: (res) => {
        setData(res.data);
      },
      enabled: !!id,
    },
  });

  return (
    <div className="">
      <HeaderExtraLayout
        title={data?.data?.examControl?.name ?? "Exam control"}
        isBack
        backUrl={urlValue?.filter?.subject_id ? `/subjects/${urlValue?.filter?.subject_id}?semestr_id=${data?.data?.edu_semestr_id}&tab=exam_control` : `/exams`}
        breadCrumbData={[
          { name: "Home", path: "/" },
          { name: "Subjects", path: "subjects/" },
          {
            name: "Subject view",
            path: urlValue?.filter?.subject_id ? `/subjects/${urlValue?.filter?.subject_id}?semestr_id=${data?.data?.edu_semestr_id}&tab=exam_control` : `/exams`,
          },
          { name: "Exam control", path: "exam-control" },
        ]}
        btn={
          <CreateBtn
            onClick={() => {}}
            permission={"exam-control-student_create"}
          />
        }
      />
      <Spin spinning={isFetching}>
        {urlValue?.filter_like?.type === "1" ? (
          <QuestionExamType data={data?.data} type={type} />
        ) : urlValue?.filter_like?.type === "2" ? (
          !data?.data?.user_status && type !== "view" ? (
            <TestExamType data={_data} refetch={refetch} />
          ) : (
            <TestView data={data?.data} />
          )
        ) : null}
      </Spin>
    </div>
  );
};

export default ExamControlStudent;

/**
 * exam-control-student_index
 * exam-control-student_delete
 * exam-control-student_update
 * exam-control-student_view
 */
