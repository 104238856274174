import React, { useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { Button, Col, Divider, Modal, Row, Upload, UploadProps, message } from 'antd';
import { AxiosError } from 'axios';
import instance from 'config/_axios';
import { editor_buttonList } from 'config/constants/suneditor';
import { IExamControlStudent } from 'models/exam';
import FileViewer from 'components/FileViewer/file_viewer';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import SunEditor from 'suneditor-react';
import { Notification } from 'utils/notification';
import { AttachFilled, ExpandUpRightRegular } from '@fluentui/react-icons';
import { FILE_URL } from 'config/utils/index - Copy';
import "./style.scss";

type TypeQuestionExamType = {
  data: IExamControlStudent | undefined;
  type: string | undefined;
};

const props: UploadProps = {
  name: 'file',
  maxCount: 1,
  customRequest: ({ onSuccess }: any) => { onSuccess("ok") },
  className: "inline-block",
  // headers: {
  //   authorization: 'authorization-text',
  // },

  onChange(info) {
    if (info.file.status === 'done') {
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === 'error') {
      info.file.status = 'done';
      message.error(`${info.file.name} file upload failed.`);
    }
  },
};

const QuestionExamType: React.FC<TypeQuestionExamType> = ({ data, type }): JSX.Element => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { t } = useTranslation();
  const [text, settext] = useState<string>(data?.answer_text ?? "");
  const [open, setOpen] = useState<{ file?: string | any, open: boolean }>({ open: false });
  const [file, setFile] = useState<any>();

  const { mutate, isLoading } = useMutation({
    mutationFn: async () => {
      // const text = JSON.parse(localStorage.getItem(`exam_control_text_${id}`) ?? "0");
      if (text || file) {
        const formdata = new FormData();
        if (text) formdata.append("answer_text", text);
        if (file) formdata.append("answer_file", file?.originFileObj);

        const response = await instance({ url: `/exam-control-students/finish/${id}`, method: "PUT", data: formdata });

        return response.data;
      } else {
        message.error("Javob matni yoki file to'ldirilishi shart!!!");
        return
      }
    },
    onSuccess: async (res) => {
      if (res.status) {
        Notification("success", "create", res?.message ?? "");
        localStorage.removeItem(`exam_control_text_${id}`);
        navigate(-1);
      }
    },
    onError: (error: AxiosError<any>) => {
      Notification("error", "create", error?.response?.data?.message ?? "");
    },
    retry: 0,
  });

  return (
    <div className="relative question-wrapper ">
      <Row>
        <Col span={24} className='px-4' >
          <Divider orientation='left' ><b className='text-md text-blue-600 mb-4' >Topshiriq</b></Divider>
          <div className="">
            <div className='w-full leading-5 mb-6' dangerouslySetInnerHTML={{ __html: data?.examControl?.question ?? "" }} />
            {type === "view" ? data?.examControl?.file ? <div className='d-f mt-6' >
              <span className="opacity-60">Tposhiriq fayli:</span>
              <Button onClick={() => setOpen({ open: true })} type='primary' size='small' className='inline-flex items-center ms-4'>
                <ExpandUpRightRegular fontSize={16} />&nbsp;&nbsp;File
              </Button>
            </div> : null
              : <div className="w-[90%] mx-auto">
                {data?.examControl?.file ? <FileViewer file={data?.examControl?.file} /> : null}
              </div>
            }
          </div>
          <div className="w-full  my-4 mb-16">
            <Divider orientation='left' ><b className='text-md text-green-600' >Javob</b></Divider>
            {type !== "view" ? <div className='mt-2 rounded-md '>
              <SunEditor
                setContents={(data?.answer_text ?? "")}
                height='240px'
                autoFocus={true}
                placeholder={t("Enter content text") ?? ""}
                onChange={(e) => { settext(e); localStorage.setItem(`exam_control_text_${id}`, JSON.stringify(e)) }}
                setOptions={{
                  fontSize: [12, 14, 16, 18, 20, 24, 32],
                  fontSizeUnit: "px",
                  buttonList: editor_buttonList
                }} />
              <br />
              <Upload
                {...props}
                accept='.pdf'
                fileList={file ? [file] : data?.answer_file ? [{
                  uid: '-1',
                  name: 'Exam questions',
                  status: 'done',
                  url: FILE_URL + data?.answer_file,
                }] : undefined}
                onPreview={(_file) => { if (_file.originFileObj && file) setOpen({ file: window.URL.createObjectURL(_file.originFileObj), open: true }) }}
                onChange={(file) => { file.file.status === "removed" ? setFile(undefined) : setFile(file?.file) }}
              >
                <span>Javob fayli</span>
                <Button icon={<AttachFilled className='text-[18px] mr-2' />} className='items-center flex mt-1'>Click to Upload</Button>
              </Upload>
            </div> : <div>
              <div className='w-full' dangerouslySetInnerHTML={{ __html: data?.answer_text ?? "" }} />
              {data?.answer_file ? <FileViewer file={data?.answer_file} /> : null}
            </div>}
          </div>
        </Col>
      </Row>
      {type !== "view" ? <div className="fixed z-10 bottom-0 left-0 right-0 h-12 bg-[#F7F7F7] border-0 border-t border-solid border-gray-200 shadow-lg">
        <div className="w-full flex justify-end py-2 px-4 shadow-md">
          <Button type='primary' loading={isLoading} onClick={() => mutate()} >{t('Save')}</Button>
        </div>
      </div> : null}

      <Modal open={open.open} width={"90%"} style={{ top: 20 }} title="File viewer" onCancel={() => setOpen({ open: false, file: undefined })} onOk={() => setOpen({ open: false, file: undefined })} >
        {open.file ? <iframe className='w-full min-h-[460px]' src={open.file ?? ""} />
          : <FileViewer file={data?.examControl?.file ?? ""} />}
      </Modal>
    </div>
  );
};

export default QuestionExamType;