import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { Button, Divider, Modal, message } from "antd";
import { ArrowLeftRegular, ArrowRightRegular } from '@fluentui/react-icons';
import useUrlQueryParams from 'hooks/useUrlQueryParams';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import instance from 'config/_axios';
import { FILE_URL } from 'config/utils/index - Copy';
import { toHHmmss } from 'utils/others_functions';
import { IExamStudent, IExamControlTest, IExamQuestion } from 'models/exam';

type TypeTestExamType = {
  data: IExamStudent | undefined;
  refetch: () => void;
};

const TestExamType: React.FC<TypeTestExamType> = ({ data, refetch }): JSX.Element => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const { urlValue, writeToUrl } = useUrlQueryParams({});
  const [answers, setAnswers] = useState<{ [key: number]: number }>({});
  const [time, setTime] = useState<number>(0);
  const [tests, setTests] = useState<IExamQuestion[]>([]);
  const [result, setResult] = useState<any>();

  useEffect(() => {
    setTests(data?.examStudentQuestion ?? []);
    const finish = (data?.exam?.finish_time ?? 0) - (data?.studentTimes?.current ?? 0);
    const _time = (data?.exam?.duration ?? 0) * 60 - ((data?.studentTimes?.current ?? 0) - (data?.studentTimes?.start ?? 0));
    if ((data?.studentTimes?.finish ?? 0) < (data?.studentTimes?.current ?? 1)) {
      setTime(0);
    } else {
      if (_time < finish)
        setTime(_time);
      else
        setTime(finish);
    }

    if (!urlValue.filter_like?.test) {
      writeToUrl({ name: "test", value: 1 });
    }

    if (data && ((data?.studentTimes?.finish ?? 0) <= (data?.studentTimes?.current ?? 0) || (data?.exam?.finish_time ?? 0) <= (data?.studentTimes?.current ?? 0))) {
      Modal.error({
        title: "Imtihon vaqti yakunlangan!!!",
        content: "Ortga qaytish uchun 'ok' tugmasini bosing",
        onOk: () => {
          if (!data?.user_status) finishTest();
          else navigate(`/exams?tab=finaly-exam?semestr_id=${data?.edu_semestr_id}`);
        }
      });
    }

    if (data && !data?.examStudentQuestion?.length) {
      Modal.error({
        title: "Testlar mavjud emas!!!",
        content: "Ortga qaytish uchun 'ok' tugmasini bosing",
        onOk: () => { navigate(`/exams?semestr_id=${data?.edu_semestr_id}&tab=finaly-exam`) }
      });
    }
  }, [data]);

  const checkAnswer = (answer_id: number, option_id: number) => {
    checkVariant({ option_id, answer_id });
  }

  useEffect(() => {
    if (data && time > 0) {
      const interval = setInterval(() => {
        setTime(p => {
          if (p <= 0) {
            clearInterval(interval);
            Modal.warning({
              title: 'Test vaqti yakunlandi',
              content: "Ortga qaytish uchun 'ok' tugmasini bosing",
              onOk: () => { finishTest(); }
            });
            return 0;
          }
          return p - 1
        });
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [data, tests]);

  const { mutate: checkVariant, isLoading: checkLoading } = useMutation({
    mutationFn: async ({ answer_id, option_id }: { answer_id: number, option_id: number }) => {
      const formdata = new FormData();

      // formdata.append("answer_id", answer_id?.toString());
      formdata.append("student_option", option_id?.toString());

      const response = await instance({
        url: `/exam-student-questions/${answer_id}`,
        method: "PUT",
        data: formdata,
      });
      return response.data;
    },
    onSuccess: async (res) => {
      if (res?.status === 1) {
        // setTests(res.data);
        refetch();
      } else {
        message.error(res?.data ? res?.data?.message : "")
      }
    },
    onError: (error: AxiosError<any>) => {
      message.error(error?.response?.data ? error?.response?.data?.message : "")
    },
    retry: 0,
  });

  const { mutate: finishTest, isLoading: finishLoading } = useMutation({
    mutationFn: async () => {

      const response = await instance({
        url: `/exam-students/finish/${id}`,
        method: "PUT",
      });
      return response.data;
    },
    onSuccess: async (res) => {
      if (res?.status === 1) {
        navigate(`/exams?semestr_id=${data?.edu_semestr_id}&tab=finaly-exam`);
      } else {
        message.error(res?.data ? res?.data?.message : "")
      }
    },
    onError: (error: AxiosError<any>) => {
      message.error(error?.response?.data ? error?.response?.data?.message : "")
    },
    retry: 0,
  });

  console.log(time);

  return (
    <div className="">
      <div className="flex max-md:flex-col gap-6 px-6 max-md:px-2">
        <div className="w-full max-w-[980px] mx-auto">
          <div className="min-h-[260px] bg-[#F7F7F7] rounded-lg border border-solid border-gray-200 p-4 my-2 mt-6 max-md:mt-2">
            {
              tests?.map((e, i) => i + 1 == Number(urlValue.filter_like.test) && (
                <div className="test" key={i}>
                  <div className="flex max-md:flex-col gap-3">
                    <div className='w-1/2 max-md:w-full' dangerouslySetInnerHTML={{ __html: e?.question?.text ?? "" }} />
                    {e?.question?.file ? <>
                      <img src={FILE_URL + e?.question?.file} alt='image' className='w-1/2 max-md:w-full rounded-lg' />
                    </> : null}
                  </div>
                  <Divider className='-ml-4 w-[calc(100%+32px)]' />
                  <div className="variants flex flex-col gap-4 my-4">
                    {
                      e?.question?.option?.map((v: any, idx: number) => (
                        <div key={idx} className="flex gap-2" onClick={() => { e?.question?.student_option != v.id && checkAnswer(e.id, v.id) }} >
                          <div className={`h-[44px] w-[44px] flex-center rounded-lg border border-solid p-3 cursor-pointer ${e?.question?.student_option == v.id ? "bg-[#EAF0FD] text-[#3776E7] border-[#3775e750]" : "bg-[#fff] border-gray-200"}`}>{idx == 0 ? "A" : idx === 1 ? "B" : idx === 2 ? "C" : idx === 3 ? "D" : "E"}</div>
                          <div className={`w-full flex max-md:flex-col gap-3 rounded-lg border border-solid p-3 cursor-pointer ${e?.question?.student_option == v.id ? "bg-[#EAF0FD] text-[#3776E7] border-[#3775e750]" : "bg-[#fff] border-gray-200"} hover:border-[#3776E760]`}>
                            <div className='w-3/5 max-md:w-full' dangerouslySetInnerHTML={{ __html: v.text ?? "" }} />
                            {v.file ? <>
                              <img src={FILE_URL + v.file} alt='image' className='w-2/5 max-md:w-full rounded-lg' />
                            </> : null}
                          </div>
                        </div>
                      ))
                    }
                  </div>
                </div>
              ))
            }
          </div>
          <div className="flex-center my-5">
            <Button type='primary' ghost className='me-3' disabled={1 === Number(urlValue.filter_like?.test)} onClick={() => { writeToUrl({ name: "test", value: Number(urlValue.filter_like?.test) - 1 }) }} ><div className="d-f"><ArrowLeftRegular className='me-5' /> {("Back")}</div></Button>
            <Button type='primary' disabled={tests?.length <= Number(urlValue.filter_like?.test)} onClick={() => { writeToUrl({ name: "test", value: Number(urlValue.filter_like?.test) + 1 }) }} ><div className="d-f" >{("Next")} <ArrowRightRegular className='ms-5' /></div></Button>
          </div>
        </div>
        <div className="w-96 max-md:w-full h-max min-h-[320px] flex flex-col justify-between gap-4 sticky top-5 bg-[#F7F7F7] rounded-lg border border-solid border-gray-200 shadow-md- p-3 my-6">
          <div>
          <h2 className='text-center text-3xl my-5 mt-3' >{toHHmmss(time)}</h2>
            <div className="flex flex-wrap gap-2 mt-4">
              {
                tests?.map((e, i) => (
                  <div key={i} onClick={() => { writeToUrl({ name: "test", value: i + 1 }) }} className={`h-[36px] w-[36px] flex-center rounded-lg border border-solid cursor-pointer ${Number(urlValue.filter_like?.test) === i + 1 ? (e?.question?.student_option ? "text-[#3776E7] bg-[#EAF0FD]" : "text-[#3776E7] bg-white") : e?.question?.student_option ? "bg-[#EAF0FD] text-[#3776E7] border-[#3775e750]" : "bg-white border-gray-200"} hover:border-[#3776E7]`}>{i + 1}</div>
                ))
              }
            </div>
          </div>
          <div className='text-center' >
            <Button type='primary' danger onClick={() => {
              Modal.confirm({
                title: 'Testni yakunlash',
                content: <div>
                  { tests?.filter(e => !e?.question?.student_option)?.length ? <p>Test to'liq balgilanmagan</p> : null}
                  <span> Testni yakunlamoqchimisiz? </span>
                </div>,
                okText: "Yakunlash",
                cancelText: "Testga qaytish",
                onOk: () => { finishTest(result?.id) },
                onCancel() { console.log('Cancel'); }
              });
            }} className='w-full max-w-[320px]' >{("Testni yakunlash")}</Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestExamType;