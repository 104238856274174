import React, { useState } from 'react';
import { Button, Col, Divider, Modal, Row } from 'antd';
import { IExamStudent } from 'models/exam';
import FileViewer from 'components/FileViewer/file_viewer';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { ExpandUpRightRegular } from '@fluentui/react-icons';
import "./style.scss";

type TypeQuestionExamTypeView = {
  data: IExamStudent | undefined;
};

const QuestionExamTypeView: React.FC<TypeQuestionExamTypeView> = ({ data }): JSX.Element => {
  const [open, setOpen] = useState<string | null>();

  return (
    <div className="question-wrapper bg px-4 py-4">

      {
        data?.examStudentQuestion?.map((e, i) => (
          <div className="rounded-md p-4 mb-4 bg-white" key={i} >
            <div dangerouslySetInnerHTML={{ __html: e?.question?.text ?? "" }} />
            {e?.question?.file ? <div className='d-f my-4' >
              <span className="opacity-60">Tposhiriq fayli:</span>
              <Button onClick={() => setOpen(e?.question?.file)} type='primary' size='small' className='inline-flex items-center ms-4'>
                <ExpandUpRightRegular fontSize={16} />&nbsp;&nbsp;File
              </Button>
            </div> : null}
            <Divider orientation='left' ><b className='text-md text-green-600' >Javob:</b></Divider>
            <Row gutter={[12, 12]} >
              <Col xs={24} sm={24} md={24} lg={12} xl={16}>
                {/* Lorem ipsum dolor sit amet consectetur adipisicing elit. Harum excepturi, aut natus, necessitatibus accusamus voluptates, corrupti impedit vitae ipsum doloremque incidunt fuga corporis? Similique molestias quas nemo illo vero, quasi quo dolore laborum, sequi voluptas consectetur. Accusantium eligendi repellat cumque minima exercitationem quos corporis, nihil quasi et! Ex ducimus, modi aliquid provident consectetur quis rerum, nemo atque ipsum tempore voluptatibus. Sint officiis sed eum? Eligendi alias sequi nemo laudantium, animi aliquid magnam ab molestiae explicabo quibusdam maxime? Esse assumenda, optio molestiae rem ad minus dolorum fugiat? Fugiat, sequi. */}
                <div dangerouslySetInnerHTML={{ __html: e?.question?.answer_text ?? "" }} />
                {e?.question?.answer_file ? <div className='d-f my-4' >
                  <span className="opacity-60">Javob fayli:</span>
                  <Button onClick={() => setOpen(e?.question?.answer_file)} type='primary' size='small' className='inline-flex items-center ms-4 bg-green-600'>
                    <ExpandUpRightRegular fontSize={16} />&nbsp;&nbsp;File
                  </Button>
                </div> : null}
              </Col>
              <Col xs={24} sm={24} md={24} lg={12} xl={8}>
                <div>
                  {<p className='rounded-md bg-[#D6E4FF] text-[#2F54EB] py-[0.35rem] px-4 text-center' >Baholandi</p>}
                  <p className='rounded-md bg-[#daf5e1] text-green-600 font-medium py-[0.35rem] px-4 text-center mt-3' >{e?.student_ball ?? "_"} / {(data?.max_ball ?? 0) / (data?.examStudentQuestion?.length ?? 1)}</p>
                  <div className='rounded-md border border-solid border-[#e7e7e7] p-2 mt-3' >
                    {e?.desctiption}
                    {/* Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorum, maiores. Ratione perferendis enim saepe facere unde, sed placeat velit expedita commodi, rerum corporis fugit soluta sit fugiat. Omnis, maiores alias? Aperiam officia inventore necessitatibus labore? */}
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        ))
      }

      <Modal open={!!open} width={"90%"} style={{ top: 20 }} title="File viewer" onCancel={() => setOpen(undefined)} onOk={() => setOpen(undefined)} >
        <FileViewer file={open ?? ""} />
      </Modal>
    </div>
  );
};

export default QuestionExamTypeView;