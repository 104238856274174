import { useMutation } from '@tanstack/react-query';
import { Button, Modal, message } from 'antd';
import Table, { ColumnsType } from 'antd/es/table';
import { AxiosError } from 'axios';
import HeaderPage from 'components/HeaderPage';
import instance from 'config/_axios';
import dayjs from 'dayjs';
import useGetAllData from 'hooks/useGetAllData';
import useUrlQueryParams from 'hooks/useUrlQueryParams';
import { IExam } from 'models/exam';
import React, {  } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'store';
import checkPermission from 'utils/checkPermission';

const FinallyExams: React.FC = (): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { urlValue, writeToUrl } = useUrlQueryParams({});
  const user = useAppSelector(p => p.auth.user);
  const user_id = user?.user_id;

  const { data, isFetching, refetch } = useGetAllData<IExam>({
    queryKey: ["exams", urlValue.filter],
    url: "/exams?sort=-id&expand=type,examStudents,examTimes,studentMark",
    urlParams: {
      "per-page": 0,
      filter: { "edu_semestr_subject_id": urlValue?.filter?.subject_id, "edu_semestr_id": urlValue.filter?.semestr_id }
    },
    options: {
      enabled: !!urlValue.filter?.semestr_id,
    }
  });

  const { mutate: startTest, isLoading: startLoading } = useMutation({
    mutationFn: async (id: number) => {
      const formdata = new FormData();
      formdata.append("exam_id", id.toString())
      const response = await instance({
        url: `/exam-students`,
        method: "POST",
        data: formdata
      });
      return response.data;
    },
    onSuccess: async (res) => {
      if (res?.status === 1) {
        navigate(`/exam-student/${res?.data?.id}?type=${res?.data?.type}&edu_semestr_subject_id=${urlValue?.filter?.edu_semestr_subject_id}&semestr_id=${urlValue?.filter?.semestr_id}`)
      } else {
        message.error(res?.data ? res?.data?.message : "")
      }
    },
    onError: (error: AxiosError<any>) => {
      message.error(error?.response?.data ? error?.response?.data?.message : "")
    },
    retry: 0,
  });

  const columns: ColumnsType<IExam> = React.useMemo(
    () => [
      {
        title: "№",
        dataIndex: "order",
        render: (_, __, i) => i + 1,
        width: 45,
      },
      {
        title: t("Name"),
        dataIndex: "name",
      },
      {
        title: t("Type"),
        dataIndex: "type",
        render: (e) => <span>{e === 1 ? "Yozma" : e === 2 ? "Test" : ""}</span>
      },
      {
        title: t("Date"),
        render: (e) => <span>{dayjs(e?.start_time * 1000).format("YYYY.MM.DD HH:mm")} - {dayjs(e?.finish_time * 1000).format("YYYY.MM.DD HH:mm")}</span>
      },
      {
        title: t("Duration"),
        dataIndex: "duration",
        render: (e) => <span>{e ?? 0} minut</span>
      },
      {
        title: t("Ball"),
        render: (e) => <span>{e?.examStudents[0]?.student_ball ?? "_"} / {e?.max_ball}</span>,
        align: "center"
      },
      {
        title: t("Status"),
        render: (i, e) => {
          if(e?.status === 2) return <span className='rounded-md bg-[#daf5e1] text-green-600 py-1 px-3' >Active</span>
          if(e?.status === 3) return <span className='rounded-md bg-red-100 text-red-600 py-1 px-3' >Yakunlangan</span>
          if(e?.status === 4) return <span className='rounded-md bg-orange-100 text-orange-600 py-1 px-3' >Baholanmoqda</span>
          if(e?.status === 5) return <span className='rounded-md bg-[#D6E4FF] text-[#2F54EB] py-1 px-3' >E'lon qilingan</span>
        },
        align: "center"
      },
      {
        title: t("Boshlash"),
        render: (i, e) => {
          if (!checkPermission("exam-student_view")) {
            return null
          }
          if (e?.studentMark === 0) {
            return <span className='font-medium text-red-600' >Imtihonga ruxsat yo'q</span>
          }
          if (e?.status === 2 && e?.finish_time > (e?.examTimes?.current ?? 0) && ((e?.examStudents?.length && e?.examStudents[0]?.status === 1) || !e?.examStudents?.length)) {
            return <Button
              size='small'
              type='primary'
              className='px-4'
              disabled={e?.start_time > (e?.examTimes?.current ?? 0)}
              onClick={() => {
                // if (e?.examStudents?.length) {
                if (e?.type === 1 && e?.examStudents?.length) localStorage.removeItem(`exam_student_text_${user_id}_${e?.examStudents[0]?.id}`);
                Modal.confirm({
                  title: 'Testni boshlamoqchimisiz?',
                  content: <div>
                    <p className='font-medium'>
                      <span className='text-black text-opacity-50 me-2'>Savollar:</span>
                      <b>{e?.question_count ?? 0} ta</b>
                    </p>
                    <p className='font-medium'>
                      <span className='text-black text-opacity-50 me-2'>Davomiyligi:</span>
                      <b>{e?.duration ?? 0} minut</b>
                    </p>
                  </div>,
                  cancelText: "Bekor qilish",
                  okText: "Boshlash",
                  okButtonProps: {disabled: startLoading},
                  onOk: () => {
                    if (e?.examStudents?.length) {
                      navigate(`/exam-student/${e?.examStudents[0]?.id}?type=${e?.type}&edu_semestr_subject_id=${urlValue?.filter?.edu_semestr_subject_id}&semestr_id=${urlValue?.filter?.semestr_id}`)
                    } else {
                      startTest(e?.id);
                    }
                  },
                });
                // }
              }
              }
            >
              Boshlash
            </Button>
          }
          if (e?.examStudents?.length && e?.status === 5) {
            return <span
              onClick={() => {
                if (e?.examStudents?.length) {
                  if (e?.type === 1) localStorage.removeItem(`exam_student_text_${user_id}_${e?.examStudents[0]?.id}`);
                  navigate(`/exam-student/view/${e?.examStudents[0]?.id}?type=${e?.type}&edu_semestr_subject_id=${urlValue?.filter?.edu_semestr_subject_id}&semestr_id=${urlValue?.filter?.semestr_id}`)
                }
              }}
              className='rounded-md bg-[#D6E4FF] text-[#2F54EB] py-1 px-3  cursor-pointer'
            >
              Ko'rish
            </span>
          }
          if(e?.examStudents?.length && e?.examStudents[0]?.status === 2) return <span className='font-medium text-red-600' >Yakunlangan</span>
          return null
        },
        align: "center"
      },
    ],
    [data?.items]
  );

  return (
    <div className="px-6 max-md:p-2">
      <HeaderPage
        title={t('Student Exam')}
        className=''
        create_permission=''
        createOnClick={() => { }}
      />
      <Table
        columns={columns}
        dataSource={data?.items}
        // bordered
        pagination={false}
        loading={isFetching}
        size="middle"
        className="mt-3"
        rowClassName="py-[12px]"
        scroll={{ x: 576 }}
      />
    </div>
  );
};

export default FinallyExams;