import { ColumnsType } from 'antd/es/table';
import { Button, Col, Modal, Row, Segmented, Select, Table } from "antd";
import HeaderExtraLayout from 'components/HeaderPage/headerExtraLayout';
import React from 'react'
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { IExamControl } from 'models/exam';
import useGetAllData from 'hooks/useGetAllData';
import useUrlQueryParams from 'hooks/useUrlQueryParams';
import { useNavigate } from 'react-router-dom';
import { IEduSemestr } from 'models/education';
import useGetData from 'hooks/useGetData';
import HeaderUserView from 'pages/users/components/vewHeader';
import ExamControl from 'pages/subject/components/exam_control';
import FinallyExams from './components/exam';
import FilterSelect, { TypeFilterSelect } from 'components/FilterSelect';
import { cf_filterOption, generateAntdColSpan } from 'utils/others_functions';

const selectData: TypeFilterSelect[] = [
  {
    name: "edu_semestr_id",
    label: "Semestr",
    url: "edu-semestrs",
    permission: "edu-semestr_index",
    // value_name: "semestr",
    child_names: ["edu_semestr_subject_id"]
  },
  {
    name: "edu_semestr_subject_id",
    label: "Subject",
    url: "edu-semestr-subjects",
    permission: "edu-semestr-subject_index",
    value_name: "subject",
    parent_name: "edu_semestr_id"
  },
];

const Exams: React.FC = (): JSX.Element => {
  const { t } = useTranslation();
  const { urlValue, writeToUrl } = useUrlQueryParams({});

  const { data: semestrs, isFetching: isSemestrFetching } = useGetData<IEduSemestr>({
    queryKey: ["edu-semestrs"],
    url: `edu-semestrs?expand=semestr,eduSemestrSubjects.subject`,
    urlParams: { "per-page": 0 },
    options: {
      onSuccess: (res) => {
        writeToUrl({ name: "semestr_id", value: res?.items?.find(e => e?.status)?.id ?? res.items[0]?.id })
      },
    }
  });

  // const { data: subjects, isFetching: isSubjectFetching } = useGetData<IEduSemestr>({
  //   queryKey: ["edu-semestr-subjects"],
  //   url: `edu-semestr-subjects?expand=subject`,
  //   urlParams: { "per-page": 0 },
  //   options: {
  //     onSuccess: (res) => {
  //       writeToUrl({ name: "semestr_id", value: res?.items?.find(e => e?.status)?.id ?? res.items[0]?.id })
  //     },
  //   }
  // });

  return (
    <div className="">
      <HeaderUserView
        title={t('Exams')}
        breadCrumbData={[
          {
            name: "Home",
            path: "/"
          },
          {
            name: "Exams",
            path: "/exams"
          },
        ]}
        btn={<Row gutter={[12, 12]} >
          <Col {...generateAntdColSpan({ xs: 24, sm: 24, md: 24, lg: 24, xl: 12 })}>
            <Select
              className="w-[100%] min-w-[220px]"
              placeholder={`${t(`Filter by semestr`)}`}
              allowClear
              // disabled={!urlValue.filter?.semestr_id}
              value={ urlValue?.filter?.semestr_id }
              onChange={(e) => {writeToUrl({name: "semestr_id", value: e})}}
              // onClear={handleClear}
              // onFocus={() => !data?.items?.length && refetch()}
              showSearch
              filterOption={cf_filterOption}
              loading={isSemestrFetching}
            >
              {
                semestrs?.items?.length ? semestrs.items.map((element: any) =>
                  <Select.Option key={element.id} value={element.id}>{element?.semestr?.name}</Select.Option>
                ) : null
               }
            </Select>
          </Col>
          <Col {...generateAntdColSpan({ xs: 24, sm: 24, md: 24, lg: 24, xl: 12 })}>
            <Select
              className="w-[100%] min-w-[220px]"
              placeholder={`${t(`Filter by semestr`)}`}
              allowClear
              // disabled={!urlValue.filter?.semestr_id}
              value={ urlValue?.filter?.subject_id }
              onChange={(e) => {writeToUrl({name: "subject_id", value: e})}}
              // onClear={handleClear}
              // onFocus={() => !data?.items?.length && refetch()}
              showSearch
              filterOption={cf_filterOption}
              loading={isSemestrFetching}
            >
              {
                semestrs?.items?.length ? semestrs.items?.find(e => e?.id === urlValue.filter?.semestr_id)?.eduSemestrSubjects?.map((element: any) =>
                  <Select.Option key={element.id} value={element.id}>{element?.subject?.name}</Select.Option>
                ) : null
               }
            </Select>
          </Col>
        </Row>}
        tabs={[
          {
            key: "exam_control",
            label: t("Exam control"),
            children: <ExamControl id={'0'} />
          },
          {
            key: "finaly-exam",
            label: t("Finally exam"),
            children: <FinallyExams />
          },
        ]}
      />
    </div>
  );
}

export default Exams